import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Font24 from "components/Fonts/Font24"
import Font18 from "components/Fonts/Font18"
import Font12 from "components/Fonts/Font12"
import { Card, Grid } from "@mui/material"
import Button from "components/Buttons/Button"
import { Dot } from "./styles"

const NewsPostCardLarge = ({
  title,
  mainImage,
  createdAt,
  author,
  shortDescription,
  contentful_id,
  slug,
  ...rest
}) => {
  const image = getImage(mainImage)
  return (
    <Grid item xs={12} container {...rest}>
      <Card
        sx={{
          padding: 0,
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
        style={{ width: "100%" }}
      >
        <GatsbyImage image={image} alt={title} />
        <Grid px={5} py={4} container>
          <Grid item xs={12} sm={9} container direction="column">
            <Grid
              item
              container
              alignItems="start"
              justifyContent="space-between"
              mb={2}
            >
              <Grid item xs={12} lg={8}>
                <Font24>{title}</Font24>
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                mt={0.5}
                container
                justifyContent={{ xs: "start", lg: "end" }}
              >
                <Font12 style={{ display: "flex", alignItems: "center" }}>
                  {createdAt}
                  <Dot />
                  <b>
                    {author.name} {author.surname}
                  </b>
                </Font12>
              </Grid>
            </Grid>
            <Grid item sx={{ minHeight: 55 }}>
              <Font18 color="secondary">{shortDescription}</Font18>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} container mt={{ xs: 3, md: 0 }}>
            <Grid
              container
              alignItems="end"
              justifyContent={{ xs: "center", md: "end" }}
            >
              <Button to={`/aktualnosci/${slug}`}>Czytaj więcej</Button>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}

export default NewsPostCardLarge
