import { Grid } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import NewsPostCardLarge from "../NewsPostCardLarge/NewsPostCardLarge"
import { default as PaginationComponent } from "components/Pagination/Pagination"
import Font22 from "components/Fonts/Font22"

const NewsCards = () => {
  const query = useStaticQuery(graphql`
    query NewsPost {
      allContentfulPostAktualnosci(
        sort: { order: DESC, fields: createdAt }
        filter: { hideSite: { eq: false } }
      ) {
        nodes {
          slug
          createdAt(formatString: "DD.MM.yyyy")
          title
          contentful_id
          shortDescription
          author {
            name
            surname
            position
          }
          mainImage {
            gatsbyImageData(
              width: 300
              height: 150
              quality: 70
              backgroundColor: "#e5f5f7"
              placeholder: NONE
            )
          }
        }
      }
    }
  `)

  const posts = query.allContentfulPostAktualnosci.nodes

  const [page, setPage] = useState(1)

  const postsCopy = [...posts]
  const parsedItemsArray = postsCopy.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 5)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  return (
    <section>
      <Grid container direction="column" spacing={4}>
        {!!parsedItemsArray.length ? (
          parsedItemsArray?.[page - 1]?.map(post => (
            <NewsPostCardLarge {...post} key={post.contentful_id} />
          ))
        ) : (
          <Grid item>
            <Font22 mt={6} mb={6}>
              Brak aktualności
            </Font22>
          </Grid>
        )}
      </Grid>
      <Grid my={4}>
        <PaginationComponent
          count={parsedItemsArray.length}
          hideSelect
          page={page}
          onChange={(e, value) => setPage(value)}
        />
      </Grid>
    </section>
  )
}

export default NewsCards
